import {Component, OnDestroy, OnInit} from '@angular/core';
import {Params, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {Page} from '../../../model/pim-response/page';
import {LocalizedString} from '../../../model/localized-string';
import {TableColumn} from '../../../model/table-column';
import {CustomViewType} from '../../../model/custom-view/custom-view-type';
import {CustomViewSearchService} from '../../../service/customview-search.service';
import {LocalizedStringService} from '../../../service/localized-string.service';
import {TableService} from '../../../service/table.service';
import {OverviewService} from '../../../service/overview/overview.service';

@Component({
  selector: 'customview-administration',
  templateUrl: './customview-administration.component.html'
})
export class CustomViewAdministrationComponent implements OnInit, OnDestroy {

  public facetFields: string;
  public tableColumns: TableColumn<any>[];

  public readonly typeaheadFieldLabelPrefix: string = "label.customview.overview.typeahead."

  constructor(
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly tableService: TableService,
    private readonly overviewService: OverviewService,
    private readonly customViewSearchService: CustomViewSearchService,
    private readonly localizedStringService: LocalizedStringService
  ) { }

  ngOnInit() {
    this.initializeTableColumns();
    this.facetFields = this.tableColumns
      .filter(c => c.isFilterable)
      .map(c => c.field).join(",");
  }

  ngOnDestroy(): void {
    this.overviewService.resetPage();
  }

  public getLocalizedStringsValue = (localizedStrings: LocalizedString[], showNameNotFound: boolean = false) =>
      this.localizedStringService.getLocalizedStringsValue(localizedStrings, showNameNotFound);

  public getLocalizedStringsValueWithFallback = (localizedStrings: LocalizedString[], showNameNotFound: boolean = false) =>
    this.localizedStringService.getLocalizedStringsValueWithFallback(localizedStrings, showNameNotFound);

  public searchFunction: (queryParameters: Params) => Observable<Page<Document>> = (queryParameters: Params) => this.executeSearch(queryParameters);

  public addCustomView(){
    this.router.navigateByUrl('adminstration/view/add');
  }

  private executeSearch(queryParameters: Params): Observable<Page<Document>> {
    return this.customViewSearchService.search(queryParameters);
  }

  public getCustomViewTypeName: (customViewType: string) => (string) = (value: string) => {
    return this.translateService.instant('label.custom_view.type.' + value.toLowerCase());
  }

  private initializeTableColumns(): void {
    this.tableColumns = [
      new TableColumn("name", this.translateService.get("label.administration.customview.name"), (localizedStrings: LocalizedString[]) => this.getLocalizedStringsValue(localizedStrings), false, true),
      new TableColumn("realm", this.translateService.get("label.administration.customview.realm"), (realm: LocalizedString[]) => this.getLocalizedStringsValue(realm), true, true),
      new TableColumn("type", this.translateService.get("label.administration.customview.view_type"), (type: CustomViewType) => this.tableService.enumToTranslationStringFunction(type, "option.administration.customview.view_type"), true, true),
      new TableColumn("templateFileNames", this.translateService.get("label.administration.customview.template_filename"), (templatefilenames: LocalizedString[]) => this.getLocalizedStringsValueWithFallback(templatefilenames), true, true)
    ];
  }
}

import { FacetMapEntry } from './../../model/pim-response/facet-map-entry';
import { Component, Input, QueryList, ContentChildren, Output, EventEmitter, OnChanges, ViewChildren } from '@angular/core';
import { SortType } from '../../model/sort-type';
import { Page } from '../../model/pim-response/page';
import { FacetPage } from '../../model/pim-response/facet-page';
import { Pageable } from '../../model/pageable';
import { TableColumnComponent } from './column/table-column.component';
import { FacetSelection } from '../../model/facet-selection';
import { TableFacetComponent } from './facet/table-facet.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.less'],
})
export class TableComponent implements OnChanges {

  @Input()
  public set page(page: Page<any> | FacetPage<any>) {
    this._page = new FacetPage(page);
  }

  public get page() {
    return this._page;
  }

  private _page: FacetPage<any>;

  @Output()
  public rowClick = new EventEmitter<any>();

  @Output()
  public pageableHandler = new EventEmitter<Pageable>();

  @ContentChildren(TableColumnComponent)
  public columns: QueryList<TableColumnComponent>;

  @ViewChildren(TableFacetComponent)
  public tableFacetComponents: QueryList<TableFacetComponent>;

  private pages: number[];

  private pageable: Pageable = new Pageable();

  ngOnChanges() {
    this.updateColumnFacets();
  }

  private updateColumnFacets(): void {
    if (!!this.columns && !!this._page.facets) {
      this.columns.forEach(column => {
        if (this._page.facets.has(column.field)) {
          const isInitialization: boolean = column.facets.length === 0;
          column.facets = this.updateFacetSelection(column, this._page);
          if (isInitialization) {
            column.facetsInitialize.emit(column.facets);
          }
        }
      });
    }
  }

  private updateFacetSelection(column: TableColumnComponent, page: FacetPage<Document>): FacetSelection[] {
    const backupSelectedFacetSelections: FacetSelection[] = column.facets.filter(facetSelection => !!facetSelection.selected);
    const updatedFacetSelections: FacetSelection[] = page.facets.get(column.field).map(facetMapEntry => new FacetSelection(facetMapEntry.value, facetMapEntry.count, column.field));
    const selectedUpdatedFacetSelections = updatedFacetSelections
      .filter(facetSelection => !!backupSelectedFacetSelections
        .find(oldFacetSelection => facetSelection.field === oldFacetSelection.field && facetSelection.value === oldFacetSelection.value))

    selectedUpdatedFacetSelections.forEach(facetSelection => {
      facetSelection.selected = true;
    });

    return updatedFacetSelections;
  }

  public facetsChange(column: TableColumnComponent): void {
    column.facetsChange.emit(column.facets);
  }

  public doClick(row: any) {
    this.rowClick.emit(row);
  }

  public columnValue(column: TableColumnComponent, value: any): string {
    return column.columnValue(value);
  }

  public goToPage(pageNumber: number): void {
    this.pageable.page = pageNumber;
    if ((this.pageable.size * pageNumber) >= environment.maxTableItems) {
      this.pageable.page = this.getMaxPages();
      this.page.totalPages = this.getMaxPages()+1;
      this.page.hasNext = false;
    }
    this.pageableHandler.emit(this.pageable);
  }

  public calculatePageRange() {
    this.pages = [];
    const range = Math.min(5, this.page.totalPages);
    let startPage = this.getCurrentPage() - Math.floor(range / 2);
    if (startPage < 0) {
      startPage = 0;
    }
    for (let i = 0; i < range; i++) {
      if (startPage + i <= (this.page.totalPages > this.getMaxPages() ? this.getMaxPages() : this.page.totalPages)) {
        this.pages.push(startPage + i);
      }
    }
    return true;
  }

  public toggleSort(column: TableColumnComponent) {

    this.columns.forEach(columnToReset => {
      if (columnToReset !== column) {
        columnToReset.sortDirection = SortType.NONE
      }
    });

    switch (column.sortDirection) {
      case SortType.ASC:
        column.sortDirection = SortType.DESC;
        break;
      case SortType.DESC:
        column.sortDirection = SortType.ASC;
        break;
      default:
        column.sortDirection = SortType.ASC;
        break;
    }

    column.sortChange.emit(column);
  }

  public getSortIconName(column: TableColumnComponent): string {
    switch (column.sortDirection) {
      case SortType.ASC:
        return 'sort-up';
      case SortType.DESC:
        return 'sort-down';
      default:
        return 'sort';
    }
  }

  public getColumnFacets(columnName: string): FacetMapEntry[] {
    if (!!this._page.facets && this._page.facets.has(columnName)) {
      return this._page.facets.get(columnName);
    }
    return [];
  }

  public getPages(): number[] {
    return this.pages;
  }

  getCurrentPage(): number {
    return this.page.number === undefined ? this.page.currentPage : this.page.number;
  }

  protected readonly environment = environment;

  getMaxPages() {
    return Math.ceil(environment.maxTableItems / this.pageable.size) - 1;
  }
}

import { Observable } from 'rxjs';
import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { Params, Router } from '@angular/router';
import { Page } from '../../../model/pim-response/page';
import { AttributeService } from '../../../service/attribute.service';
import { AttributeEntity } from '../../../model/attribute-entity';
import { TableColumn } from '../../../model/table-column';
import { LocalizedString } from '../../../model/localized-string';
import { TranslateService } from '@ngx-translate/core';
import { CamelCasePipe } from '../../../pipes/camel-case.pipe';
import { LocalizedStringService } from '../../../service/localized-string.service';
import { OverviewService } from '../../../service/overview/overview.service';

@Component({
  selector: 'attribute-administration',
  templateUrl: './attribute-administration.component.html',
  styleUrls: ['./attribute-administration.component.less']
})
export class AttributeAdministrationComponent implements OnInit, OnDestroy {

  @ViewChild("relevanceIconTemplate", { static: true })
  private readonly relevanceIconTemplate: TemplateRef<any>;

  public attributes: Page<AttributeEntity>;
  public facetFields: string;
  public tableColumns: TableColumn<any>[];
  public readonly typeaheadFieldLabelPrefix : string = "label.administration.attribute.overview.typeahead."

  constructor(
    private readonly router: Router,
    private readonly overviewService: OverviewService,
    private readonly attributeService: AttributeService,
    private readonly translateService: TranslateService,
    private localizedStringService: LocalizedStringService
  ) {
  }

  ngOnInit() {
    this.initializeTableColumns();
    this.facetFields = this.tableColumns
      .filter(c => c.isFilterable)
      .map(c => c.field).join(",");
  }

  ngOnDestroy(): void {
    this.overviewService.resetPage();
  }

  public getLocalizedStringsValue = (localizedStrings: LocalizedString[], showNameNotFound: boolean = false) =>
      this.localizedStringService.getLocalizedStringsValue(localizedStrings, showNameNotFound);

  public searchFunction: (queryParameters: Params) => Observable<Page<Document>> = (queryParameters: Params) =>
      this.executeSearch(queryParameters);

  public executeSearch(queryParameters: Params): Observable<Page<Document>> {
    return this.attributeService.searchEntities(queryParameters);
  }

  public editEntity(attributeEntity: AttributeEntity) {
    let url = "";
    switch(attributeEntity.entity){
      case "ATTRIBUTE":
        url = 'adminstration/attribute/';
        break;
      case "PARAMETER":
        url = 'adminstration/attribute/parameter/';
        break;
      default:
        url = 'adminstration/attribute/cluster/';
        break;
    }
    this.router.navigateByUrl(url + attributeEntity.id);
  }

  public newAttribute() {
    this.router.navigateByUrl('adminstration/attribute/newAttribute');
  }

  public newAttributeCluster()
  {
    this.router.navigateByUrl('adminstration/attribute/cluster/newAttributeCluster');
  }

  public newAttributeParameter()
  {
    this.router.navigateByUrl('adminstration/attribute/parameter/newAttributeParameter');
  }

  public getAttributeTypeName: (attribute: string) => (string) = (value: string) => {

    if(value == null) {
      return '';
    }

    const camelCasePipe = new CamelCasePipe();
    let transformedString = camelCasePipe.transform('label.type.' + value.toLowerCase());

    return this.translateService.instant(transformedString);
  }

  public getRelevanceName: (relevance: string) => (string) = (value: string) => {
    return this.translateService.instant('label.relevance.' + value.toLowerCase());
  }

  private initializeTableColumns(): void {
    this.tableColumns = [
      new TableColumn("entity", this.translateService.get("label.attribute.entity"), (entity: string) => entity, true, true),
      new TableColumn("name", this.translateService.get("label.administration.name"), (localizedStrings: LocalizedString[]) => this.getLocalizedStringsValue(localizedStrings), false, true),
      new TableColumn("type", this.translateService.get("label.attribute.type"), (attributeType: string) => this.getAttributeTypeName(attributeType), true, true, (attributeType: string) => this.getAttributeTypeName(attributeType), true),
      new TableColumn("unit", this.translateService.get("label.unit"), (localizedStrings: LocalizedString[]) => this.getLocalizedStringsValue(localizedStrings), true, true),
      new TableColumn("attributeCluster", this.translateService.get("label.attributecluster"), (localizedStrings: LocalizedString[]) => this.getLocalizedStringsValue(localizedStrings), true, true),
      new TableColumn("attributeRelevance", this.translateService.get("label.attributerelevance"), null, true, false, (relevance: string) => this.getRelevanceName(relevance), true, this.relevanceIconTemplate)
    ];
  }

}

import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { Params, Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import InjectIsReadonlyUser from '../../decorator/inject-is-readonly-user.decorator';
import * as _ from 'lodash';
import { Category } from '../../model/category';
import { Document } from '../../model/document/document';
import { Page } from '../../model/pim-response/page';
import { User } from '../../model/user';
import { Realm } from '../../model/realm/realm';
import { TableColumn } from '../../model/table-column';
import { DocumentVersion } from '../../model/document-version/document-version';
import { LocalizedString } from '../../model/localized-string';
import { RealmRestrictionType } from '../../model/realm/realm-restriction-type';
import { UrlParameter } from '../../model/parameter/url-parameter';
import { DocumentSearchService } from '../../service/document-search.service';
import { LocalizedStringService } from '../../service/localized-string.service';
import { TableService } from '../../service/table.service';
import { DocumentBackendService } from '../../service/document-backend.service';
import { OverviewService } from "../../service/overview/overview.service";
import { ClipboardService } from '../../service/clipboard.service';
import { ParamsFromStringPipe } from "../../pipes/params-from-string.pipe";

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.less']
})
export class DocumentComponent implements OnInit, OnDestroy {

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  @ViewChild('lockIconTemplate', { static: true })
  private readonly lockIconTemplate: TemplateRef<any>;

  @ViewChild('docBridgeIconTemplate', { static: true })
  private readonly docBridgeIconTemplate: TemplateRef<any>;

  @Input()
  public materialId: string;

  @Input()
  public addAllowed = true;

  @Input()
  public customParameters: string;

  @Input()
  public showRemoveButton = false;

  public readonly typeaheadFieldLabelPrefix: string = 'label.document.overview.typeahead.'

  public facetFields: string;

  public tableColumns: TableColumn<any>[];

  public searchFunction: (queryParameters: Params) => Observable<Page<Document>> = (queryParameters: Params) => this.executeSearch(queryParameters);
  public rowClicked: (rowParam: any) => void = (rowParam: any) => this.navigateToEditView(rowParam);
  public addFunction: () => void = () => this.navigateToEditView({id: 'add'});
  public copySearchFunction: () => void = () => this.clipboardService.copy(window.location.origin + "/#" + this.router.url);

  constructor(
    private readonly router: Router,
    private readonly tableService: TableService,
    private readonly overviewService: OverviewService,
    private readonly translateService: TranslateService,
    private readonly documentService: DocumentBackendService,
    private readonly documentSearchService: DocumentSearchService,
    private readonly localizedStringService: LocalizedStringService,
    private readonly paramsFromStringPipe: ParamsFromStringPipe,
    private readonly clipboardService: ClipboardService,
    ) {
  }

  public get processingDownloadSearch(): BehaviorSubject<boolean> {
    return this.documentSearchService.processingDownloadSearch;
  }

  public downloadExcelResult(){
    this.documentSearchService.downloadExcelResult();
  }

  ngOnInit(): void {
    this.initializeTableColumns();
    this.facetFields = this.tableColumns
      .filter(c => c.isFilterable)
      .map(c => c.field).join(',');
  }

  ngOnDestroy(): void {
    this.overviewService.resetPage();
  }

  public statusValueFunction: (value: string) => string = (value: any) => {
    let returnValue: string = '';
    if (typeof(value) === 'string') {
      this.translateService.get('document.' + value.toLowerCase()).subscribe((newValue: string) => returnValue = newValue)
    }
    return returnValue;
  }

  public executeSearch(queryParameters: Params): Observable<Page<Document>> {
    let queryParams;
    if(!!this.materialId) {
      queryParams = [new UrlParameter('materialIdFacet', [this.materialId])].reduce((params: Params, urlParameter: UrlParameter) =>
        Object.assign(params, { [urlParameter.field]: urlParameter.value }), queryParameters);
    } else {
      queryParams = queryParameters;
    }
    return this.documentSearchService.search(queryParams);
  }

  private initializeTableColumns(): void {
    this.tableColumns = [
      new TableColumn('type', this.translateService.get('label.document.type'), (category: Category) => this.tableService.localizedStringPropertyValueFunction(category, 'name'), true, true),
      new TableColumn('name', this.translateService.get('label.document.name'), (localizedStrings: LocalizedString[]) => this.localizedStringService.getLocalizedStringsValue(localizedStrings), false, true),
      new TableColumn('securityClass', this.translateService.get('label.document.security_classification'), (category: Category) => this.tableService.localizedStringPropertyValueFunction(category, 'name'), true, true),
      new TableColumn('operatingDivisions', this.translateService.get('label.document.operating_division'), (operatingDivisions: string[]) => this.formatOperatingDivisions(operatingDivisions), true, false),
      new TableColumn('realm', this.translateService.get('label.document.realm'), (realm: Realm) => this.tableService.localizedStringPropertyValueFunction(realm, 'name'), true, true),
      new TableColumn('createdBy', this.translateService.get('label.document.created.by'), (user: User) => this.tableService.propertyValueFunction(user, 'cn'), true, true),
      new TableColumn('status', this.translateService.get('label.status'), (status: string) => this.statusValueFunction(status), true, true, (status: string) => this.statusValueFunction(status), true),
      new TableColumn('versionLanguageIsoCodes', this.translateService.get('label.document.language'), (documentVersions: DocumentVersion[]) => this.formatVersionLanguages(documentVersions), true, false),
      new TableColumn('docBridgeDocument', of(''), null, false, false, null, false, this.docBridgeIconTemplate),
      new TableColumn('realmRestriction', of(''), null, false, false, null, false, this.lockIconTemplate)
    ];
    if(this.showRemoveButton) {
      this.tableColumns.push(new TableColumn('remove', of(''), null, false, false, null, false, this.lockIconTemplate));
    }
  }

  private formatVersionLanguages(versions: DocumentVersion[]): string {
    if (versions) {
      const languages: string[] = [];
      versions.forEach(version => {
        version.languages.forEach(language => {
          languages.push(language.isoCode);
        });
      });

      return _(languages).uniq().join(', ');
    }
    return '';
  }

  private formatOperatingDivisions(operatingDivisions: string[]): string {
    if (operatingDivisions) {
      return _(operatingDivisions).uniq().join(', ');
    }
    return '';
  }

  isRestricted(realmRestriction: RealmRestrictionType): boolean{
    return !!realmRestriction && realmRestriction !== RealmRestrictionType.NONE;
  }

  private readonly navigateToEditView: any = (rowParam: any) => {
    if(rowParam.docBridgeDocument) {
      this.documentService.getTemplateViewMaterial(rowParam.id).subscribe( (resp: any) => {
        return this.router.navigate([`document/document-creation/edit`],
          { queryParams: { material: resp.materialId, templateView: resp.templateViewId, context: 'document' } });
      });
    } else {
      return this.router.navigate([`document/${rowParam.id}`], { queryParams: this.paramsFromStringPipe.transform(this.customParameters) });
    }
  }

}

<div class="parameter-container">

  <!-- Header -->
  <div class="row g-0">

    <div *ngFor="let header of sortedAttributeAttributePatameters" class="parameter-header col-3">
      <div  class="inner-parameter-value-container px-3 py-2 font-header-row">
        <span>{{ header.attributeParameter.name | localizedStringsFilter }}</span>
        <span *ngIf="header.attributeParameter?.unit?.symbol" class="unit"> ({{ header.attributeParameter?.unit?.symbol }})</span>
      </div>
    </div>

    <div class="attribute-header col">
      <div  class="inner-attribute-value-container font-header-row px-3 py-2 ms-3">
        <span>{{ attribute.name | localizedStringsFilter }} {{ 'label.value' | translate }}</span>
      </div>
    </div>

    <div *ngIf="attributeValues.length > 1" class="button-spacer"></div>

  </div>

  <!-- Data Rows -->
  <div *ngFor="let attributeValue of attributeValues; let first = first" class="row g-0">

    <!-- Parameters -->
    <div *ngFor="let parameterValue of getParameterValuesSorted(attributeValue.attributeParameterValues)"
        class="parameter-value col-3">

      <div class="inner-parameter-value-container p-2">
        <ng-container *ngTemplateOutlet="parameterValueTemplate;context:{$implicit:{
            attribute: attribute,
            parameterValue: parameterValue,
            categoryRelation: categoryRelation,
            parameter: parameterValue.attributeParameter,
            showInfoButton: showInfoButton,
            attributeValue: attributeValue,
            readonly:readonly}}">
        </ng-container>
      </div>

    </div>

    <!-- Attribute Value -->
    <div class="attribute-value col">
      <div class="inner-attribute-value-container p-2 ms-3">
        <div [ngSwitch]="attribute.type" id="attribute-value-{{ attribute.name | localizedStringsFilter }}-{{ attribute.id }}">

          <string-edit *ngSwitchCase="'STRING'" [attributeValue]="attributeValue" [readonly]="readonly"></string-edit>
          <star-attribute-edit *ngSwitchCase="'STAR_RATING'" [attributeValue]="attributeValue" [readonly]="readonly"></star-attribute-edit>
          <number-input-edit *ngSwitchCase="'NUMBER'" [attributeValue]="attributeValue" [readonly]="readonly"></number-input-edit>
          <boolean-attribute-edit *ngSwitchCase="'BOOL'"  [attributeValue]="attributeValue" [readonly]="readonly"></boolean-attribute-edit>
          <range-edit *ngSwitchCase="'RANGE'" [attributeValue]="attributeValue" [readonly]="readonly"></range-edit>
          <single-choice-list-attribute-edit *ngSwitchCase="'ENUMERATION'" [attributeValue]="attributeValue" [attribute]="attribute" [readonly]="readonly"></single-choice-list-attribute-edit>
          <localized-enumeration-attribute-edit *ngSwitchCase="'LOCALIZED_ENUMERATION'" [attributeValue]="attributeValue" [readonly]="readonly"></localized-enumeration-attribute-edit>
          <localized-string-attribute-edit *ngSwitchCase="'LOCALIZED_STRING'"
                                           [attributeValue]="attributeValue"
                                           [attribute]="attribute"
                                           [readonly]="readonly"
                                           inputType="editor"></localized-string-attribute-edit>

        </div>
      </div>
    </div>

    <!-- Icons (functions)-->
    <div *ngIf="!readonly" class="icons-value ml-3 col-auto">
      <div class="inner-attribute-value-container p-2" *ngIf="attributeValues.length > 1">
        <icon-button  buttonType="delete" (buttonClick)="removeAttributeValue(attributeValue)" buttonHideLabel="true"></icon-button>
      </div>
    </div>

  </div>

  <div class="row mt-4">
    <div class="col">
      <button *ngIf="!readonly" (click)="addAttributeValue()" class="btn btn-primary" id="button-addAttributeValue">
        <fa-icon icon="plus"></fa-icon> {{ 'button.add.parametervalue.combination' | translate }}
      </button>
    </div>
  </div>

</div>

<ng-template #parameterValueTemplate let-args>
  <div id="parameter-value-container-{{ args.parameter.name | localizedStringsFilter }}-{{ args.parameter.id }}" >
    <app-parameter-value
      [parameter]="args.parameter"
      [parameterValue]="args.parameterValue"
      [parameterValueTemplate]="parameterTypeEditTemplate"
      [showInfoButton]="args.showInfoButton"
      [attributeValue]="args.attributeValue"
      [readonly]="args.readonly">
    </app-parameter-value>
  </div>
</ng-template>

<ng-template #parameterTypeEditTemplate let-args>
  <div [ngSwitch]="args.parameter.type">
    <number-input-edit *ngSwitchCase="'NUMBER'" [parameterValue]="args.parameterValue" [readonly]="args.readonly"></number-input-edit>
    <range-edit *ngSwitchCase="'RANGE'" [parameterValue]="args.parameterValue" [readonly]="args.readonly"></range-edit>
    <string-edit *ngSwitchCase="'STRING'" [parameterValue]="args.parameterValue" [readonly]="args.readonly"></string-edit>
    <localized-enumeration-attribute-edit *ngSwitchCase="'LOCALIZED_ENUMERATION'" [parameterValue]="args.parameterValue" [readonly]="args.readonly"></localized-enumeration-attribute-edit>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ConfirmComponent } from '../component/commons/confirm/confirm.component';
import { ConfirmCopyComponent } from '../component/commons/confirm-copy/confirm-copy.component';
import * as _ from "lodash";
import { componentCanClose } from './close/can-close-service';
import { CommonComparisonService } from './comparison/common-comparison.service';

@Injectable()
export class ConfirmService {

    constructor(
        private modalService: NgbModal,
        private commonComparisonService: CommonComparisonService
    ) { }



    public confirm(title: string, text: string, yes: string, no: string, cancel?: string): Promise<boolean> {
        const modalRef: NgbModalRef = this.modalService.open(ConfirmComponent, { windowClass: 'modal-style-class'});
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.text = text;
        modalRef.componentInstance.yes = yes;
        modalRef.componentInstance.no = no;
        modalRef.componentInstance.cancel = cancel;
        return modalRef.result;
    }


    public confirmWithCopy(title: string, text: string, yes: string, no: string, copyTitle: string, copyText: string): Promise<boolean> {
        const modalRef: NgbModalRef = this.modalService.open(ConfirmCopyComponent, { windowClass: 'modal-style-class'});
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.text = text;
        modalRef.componentInstance.yes = yes;
        modalRef.componentInstance.no = no;
        modalRef.componentInstance.copyTitle = copyTitle;
        modalRef.componentInstance.copyText = copyText;
        return modalRef.result;
    }

    public checkIfRouteCanBeChanged<T>(getOldObject: () => T, getNewObject: () => T): () => Observable<boolean> | Promise<boolean> | boolean {
        return () => {
            const hasChanges: boolean = this.commonComparisonService.areEqual(getOldObject, getNewObject);
            if (hasChanges) {
                return this.confirm('title.confirm.leave', 'text.confirm.unsaved.changes', 'button.yes', 'button.no');
            } else {
                return true;
            }
        }
    }

    public addTabOrWindowCanBeClosedCheck<T>(getOldObject: () => T, getNewObject: () => T): void{
        const oldObject: any = getOldObject();
        const newObject: any = getNewObject();
        const hasChanges: boolean = !_.isEqual(oldObject, newObject);
        const eventHandler: (event) => void = componentCanClose(() => !hasChanges);
        window.addEventListener('beforeunload', eventHandler);
    }
}

import { UserFilterParameter } from "./user-filter-parameter";

export class UserFilterChanged {


  constructor(
    public filterId: string,
    public filterType: string,
    public filterName: string,
    public filterParameters: UserFilterParameter[]) {
  }
}
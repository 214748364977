<form #f="ngForm">
  <h4>{{ 'label.pho.materials' | translate }}</h4>
  <div class="row mb-3">

    <multiple-value-selector class="form-group col-12"
      [(values)]="getProductHierarchyObject().materials"
      [typeahead]="materialTypeahead"
      [inputDisabled]="(isReadOnlyUser | async)"
      [inputName]="'pho_material'"
      [resultTemplate]="materialResultTemplate"
      [valueTemplate]="materialValueTemplate" >
    </multiple-value-selector>

  </div>

  <h4>{{ 'label.pho.children' | translate }}</h4>
  <div class="row mb-3">

    <multiple-value-selector class="form-group col-12"
      [(values)]="getProductHierarchyObject().children"
      [typeahead]="phoChildTypeahead"
      [inputDisabled]="(isReadOnlyUser | async)"
      [inputName]="'pho_child'"
      [resultFormatter]="phoChildTypeaheadFormatter"
      [valueTemplate]="phoChildValueTemplate">
    </multiple-value-selector>

  </div>

  <h4>{{ 'label.pho.hierarchy' | translate }}</h4>
  <div class="row mb-3">
    <app-tree-view  class="col-12"
      [treeViewLineTemplate]="phoTreeViewLineTemplate"
      [areNodesClickable]="false"
      [formatNodeLabel]="formatNodeLabel"
      [selectedModel]="getProductHierarchyObject()"
      [treeviewService]="getProductHierarchyObjectService()"
      [showSearchbar]="isAddView"
      [showCurrentNodeOnly]="!isAddView"
      class="col-3"></app-tree-view>
  </div>
</form>

<ng-template #materialValueTemplate let-result="result">
  <typeahead-template-material [model]="result"></typeahead-template-material>
</ng-template>

<ng-template #materialResultTemplate let-result="result" let-term="term">
  <typeahead-template-material [model]="result" [term]="term"></typeahead-template-material>
</ng-template>

<ng-template #phoTreeViewLineTemplate let-result="result">
  <app-product-hierarchy-object-line [model]="result"></app-product-hierarchy-object-line>
</ng-template>

<ng-template #phoChildValueTemplate let-result="result">
  <typeahead-template-pho-internal-name-link [model]="result"></typeahead-template-pho-internal-name-link>
</ng-template>

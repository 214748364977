<div class="modal-header">
    <h4 class="modal-title">{{ title | translate }}</h4>
</div>
<div class="modal-body">
    <p>{{ text | translate }}</p>
</div>
<div class="modal-footer">
    <button *ngIf="!!cancel" type="button" class="btn btn-secondary" (click)="modal.close()">{{ cancel | translate }}</button>
    <button type="button" class="btn btn-secondary" (click)="modal.close(false)">{{ no | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="modal.close(true)">{{ yes | translate }}</button>
</div>
import {AfterContentChecked, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {concatMap, Observable} from 'rxjs';
import {ProductHierarchyObject} from '../../../model/product-hierarchy-object/product-hierarchy-object';
import {CategoryProductHierarchyObject} from '../../../model/category-product-hierarchy-object';
import {ProductHierarchyObjectService} from '../../../service/product-hierarchy-object.service';
import {NotificationService} from '../../../service/notification.service';
import {ConfirmService} from '../../../service/confirm.service';
import {BackendValidationService} from '../../../service/form-validation/backend-validation.service';
import {CategoryProductHierarchyObjectService} from '../../../service/category-product-hierarchy-object.service';
import {HasUnsavedChangesGuard} from "../../../guards/has-unsaved-changes.guard";
import InjectIsReadonlyUser from '../../../decorator/inject-is-readonly-user.decorator';
import * as _ from 'lodash';
import { ProductHierarchyObjectUsageService } from "../../../service/product-hierarchy-object-usage.service";
import InjectIsAdmin from "../../../decorator/inject-is-admin.decorator";
import { result } from "lodash";

@Component({
  selector: 'app-product-hierarchy-object-edit',
  templateUrl: './product-hierarchy-object-edit.component.html',
  styleUrls: ['./product-hierarchy-object-edit.component.less']
})
export class ProductHierarchyObjectEditComponent implements OnInit, AfterContentChecked, HasUnsavedChangesGuard {

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  @InjectIsAdmin
  public isAdmin: Observable<boolean>;

  private originalProductHierarchyObject: ProductHierarchyObject = new ProductHierarchyObject();
  private noCanDeactivateCheck: boolean = false;
  public selectedTab = 'properties';
  public phoInUse: boolean;
  public phoHasDocumentUsage: boolean;
  public showUsages = false;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly backendValidationService: BackendValidationService,
    private readonly productHierarchyObjectService: ProductHierarchyObjectService,
    private readonly confirmService: ConfirmService,
    private readonly notificationService: NotificationService,
    private readonly categoryProductHierarchyObjectService: CategoryProductHierarchyObjectService,
    private readonly phoUsageService: ProductHierarchyObjectUsageService
  ) {
    this.loadProductHierarchyObject();
  }

  ngOnInit(): void {
    this.isReadOnlyUser.subscribe(isReadOnlyUser => this.noCanDeactivateCheck = isReadOnlyUser);
  }

  ngAfterContentChecked(): void {
    this.route.firstChild.url.subscribe((url) => {
      if (url[url.length - 1].path !== this.selectedTab) {
        this.selectedTab = url[url.length - 1].path;
      }
    });
  }

  hasUnsavedChanges(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.productHierarchyObjectService.isProductHierarchyObjectChanged(this.originalProductHierarchyObject) && !this.noCanDeactivateCheck) {
      return this.confirmService.confirm('title.confirm.leave', 'text.confirm.unsaved.changes', 'button.yes', 'button.no');
    } else {
      return true;
    }
  }

  private loadProductHierarchyObject(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id === 'add') {
      this.updateProductHierarchyObject(new ProductHierarchyObject());
      this.initializeCategory();
      return;
    }

    this.productHierarchyObjectService.load(id)
        .pipe(this.backendValidationService.renderErrorMessages())
        .subscribe(
            (productHierarchyObject: ProductHierarchyObject) => {
              if (!productHierarchyObject?.id) {
                this.router.navigate(['/product-hierarchy-objects']);
              } else {
                this.productHierarchyObjectService.loadCategoryPhos(productHierarchyObject.id);
                this.updateProductHierarchyObject(productHierarchyObject);
                this.loadUsage(productHierarchyObject);
              }
            }
        );

  }

  public cancel() {
    this.router.navigate(['/product-hierarchy-objects']);
  }

  public save(): void {
    this.productHierarchyObjectService.save(this.productHierarchyObjectService.currentProductHierarchyObject)
        .pipe(
            concatMap(productHierarchyObject => {
              this.updateProductHierarchyObject(productHierarchyObject);
              this.productHierarchyObjectService.categoryPhos.forEach((categoryPho: CategoryProductHierarchyObject) => {
                categoryPho.productHierarchyObject = productHierarchyObject;
              });
              return this.categoryProductHierarchyObjectService.save(productHierarchyObject.id, this.productHierarchyObjectService.categoryPhos)
            })
        )
        .subscribe(categoryProductHierarchyObject => {
          categoryProductHierarchyObject.forEach(cp => this.categoryProductHierarchyObjectService.createMissingAttributeValues(cp));
          this.productHierarchyObjectService.categoryPhos = categoryProductHierarchyObject;
          this.productHierarchyObjectService.categoryPhosOrig = _.cloneDeep(this.productHierarchyObjectService.categoryPhos);
          this.notificationService.addSuccessNotification('label.successfully.saved');
          this.updateUrl(this.productHierarchyObjectService.currentProductHierarchyObject);
          this.loadUsage(this.productHierarchyObjectService.currentProductHierarchyObject);
        });
  }

  private updateUrl(productHierarchyObject: ProductHierarchyObject) {
    this.router.navigateByUrl(`/product-hierarchy-object/${productHierarchyObject.id}/${this.selectedTab}`);
  }

  private updateProductHierarchyObject(productHierarchyObject: ProductHierarchyObject): void {
    this.productHierarchyObjectService.currentProductHierarchyObject = productHierarchyObject;
    this.originalProductHierarchyObject = _.cloneDeep(this.productHierarchyObjectService.currentProductHierarchyObject);
  }

  public getProductHierarchyObject() {
    return this.productHierarchyObjectService.currentProductHierarchyObject ? this.productHierarchyObjectService.currentProductHierarchyObject : null;
  }

  private initializeCategory() {
    this.productHierarchyObjectService.categoryPhos = [];
    this.productHierarchyObjectService.categoryPhosOrig = [];
    this.productHierarchyObjectService.addCategoryAndAttributes();
  }

  get productHierarchyObject() {
    return this.productHierarchyObjectService.currentProductHierarchyObject;
  }

  public delete() {
    this.confirmService.confirm('title.confirm.delete', 'text.confirm.pho.deletion', 'button.yes', 'button.no').then(confirmResult => {
      if (confirmResult) {
        this.productHierarchyObjectService.delete(this.productHierarchyObjectService.currentProductHierarchyObject).subscribe(() => {
          this.notificationService.addSuccessNotification('label.successfully.deleted');
          this.router.navigateByUrl('product-hierarchy-objects');
        });
      }
    });
  }

  public loadUsage(productHierarchyObject: ProductHierarchyObject) {
    this.phoInUse = undefined;
    this.phoUsageService.findOverallUsage(productHierarchyObject).subscribe(result => {
      this.phoInUse = result;
    });

    this.phoHasDocumentUsage = undefined;
    this.phoUsageService.findDocumentUsage(productHierarchyObject).subscribe(result => {
      this.phoHasDocumentUsage = result;
    });
  }
}

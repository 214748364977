<div class="container-fluid pb-3">
  <h2 class="display-6 fw-normal">{{ title }}</h2>

  <div class="row">

    <div class="col-6">
      <app-searchbar [typeAheadSearchFunction]="typeAheadSearchFunction"
        (buttonClick)="onSearchButtonClick($event)"
        [placeholder]="placeholder"
        [valueTemplate]="overviewValueTemplate"
        (typeaheadResultSelected)="onTypeaheadResultSelected($event)"
        [typeAheadInputFormatFunction]="typeAheadInputFormatFunction"></app-searchbar>
    </div>

    <div class="col-1">
      <button type="submit"
        class="btn btn-primary"
        id="btnCategoryReturn"
        (click)="clearFiltersAndSortAndSearch()">{{'button.clearAllFilters' | translate}}</button>
    </div>

    <div class="col left-custom-buttons"
      *ngIf="addAllowed || !!leftCustomButtons">
      <div *ngIf="!!leftCustomButtons"
        class="">
        <ng-container *ngTemplateOutlet="leftCustomButtons"></ng-container>
      </div>

      <div *ngIf="addAllowed"
        class="">
        <icon-button id="add-button-{{ editComponentUrl }}-top"
          buttonType="add"
          buttonLabel="{{'button.add' + addButtonSuffix | translate}}"
          (buttonClick)="newItem()"></icon-button>
      </div>
    </div>
    <div *ngIf="!!customButtons"
      class="col align-right">
      <ng-container *ngTemplateOutlet="customButtons"></ng-container>
    </div>

  </div>

  <div class="row" *ngIf="isAdmin | async">
    <div class="col app-user-filter">
      <app-user-filter></app-user-filter>
    </div>
  </div>

  <div class=" row">
    <div class="col pt-3">
      <app-table [page]="page"
        (rowClick)="onSelect($event)"
        (pageableHandler)="switchPage($event)">

        <app-table-column *ngFor="let tableColumn of tableColumns"
          [name]="tableColumn.name | async"
          [valueFunction]="tableColumn.valueFunction"
          [template]="tableColumn.template"
          [field]="tableColumn.field"
          [isFilterable]="tableColumn.isFilterable"
          [isSortable]="tableColumn.isSortable"
          [facetFormatting]="tableColumn.facetFormatting"
          [facetFormattingFunction]="tableColumn.facetFormattingFunction"
          (sortChange)="sortChange($event)"
          (facetsChange)="facetsChange($event)"></app-table-column>

      </app-table>
    </div>
  </div>

  <div *ngIf="addAllowed"
    class="row mb-3">
    <div class="col align-right">
      <icon-button id="add-button-{{ editComponentUrl }}-bottom"
        buttonType="add"
        buttonLabel="{{'button.add' + addButtonSuffix | translate}}"
        (buttonClick)="newItem()"></icon-button>
    </div>
  </div>

</div>
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { UserFilterChanged } from '../../model/user-filter/user-filter-changed';
import { UserFilterBackendService } from './user-filter-backend.service';
import { UserFilterResponse } from '../../model/user-filter/user-filter-response';

@Injectable({
  providedIn: 'root'
})
export class UserFilterInterfaceService {


  private _filterChanged: BehaviorSubject<UserFilterChanged> = new BehaviorSubject(null);
  private _filterTypeChanged: BehaviorSubject<string> = new BehaviorSubject("");
  private _filterType: string;

  public changeFilterType(filterType: string): void {
    if (filterType !== this._filterType) {
      this._filterType = filterType;
      this._filterTypeChanged.next(filterType);
    }
  }

  public changeFilter(userFilterChanged: UserFilterChanged): void {
    this._filterChanged.next(userFilterChanged);
  }

  public get userFilterChanged(): Subject<UserFilterChanged> {
    return this._filterChanged;
  }

  public get filterTypeChanged(): Subject<string> {
    return this._filterTypeChanged;
  }

}

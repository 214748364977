import { ParamMap, Params } from '@angular/router';
import { Injectable } from '@angular/core';
import { UrlParameter } from '../model/parameter/url-parameter';
import { SortType } from '../model/sort-type';
import { FacetSelection } from '../model/facet-selection';
import * as _ from "lodash";
import { Pageable } from '../model/pageable';
import { UserFilterParameter } from '../model/user-filter/user-filter-parameter';

@Injectable({
  providedIn: 'root'
})
export class UrlParameterService {

  public readonly SORT_ASC = "sortAsc";
  public readonly SORT_DESC = "sortDesc";

  public convertQueryParametersToString(queryParameters: Params): string {
    return Array.from(Object.entries(queryParameters))
      .map(([key, value]) => `${key}=${value}`)
      .reduce((previousValue: string, currentValue: string, currentIndex: number) => `${previousValue}${currentIndex > 0 ? "&" : "?"}${currentValue}`, "");
  }

  public convertQueryParametersToBase64(queryParameters: Params): string {
    let pageString: string;
    let base64String: string;
    let paramStrings: string[] = [];
    Array.from(Object.entries(queryParameters))
      .forEach(([key, value]) => {
        if (key === "page") {
          pageString = `&${key}=${value}`;
        } else {
          paramStrings.push(`${key}=${value}`)
        }
      });
    base64String = `?materialSearchRequestParameterString=${btoa(paramStrings
      .reduce((previousValue: string, currentValue: string, currentIndex: number) => `${previousValue}${currentIndex > 0 ? "&" : ""}${currentValue}`, ""))}`;
    return `${base64String}${!!pageString ? pageString : ""}`;
  }

  public isEmpty(urlParameter: UrlParameter): boolean {
    const isEmpty: boolean = !urlParameter.value
      || (Array.isArray(urlParameter.value) && urlParameter.value.filter(value => value !== "").length === 0)
    return isEmpty;
  }

  public convertQueryParametersToUrlParameters(queryParameters: Params, queryParameterKeys: string[]): UrlParameter[] {
    return Array.from(Object.entries(queryParameters))
      .filter(([key, value]) => queryParameterKeys.includes(key))
      .map(([key, value]) => {
        if (typeof (value) === 'string') {
          value = value.split(',');
        }
        return [key, value];
      })
      .map(([key, value]) => new UrlParameter(key, value));
  }

  public convertQueryParametersToSortParameter(queryParameterMap: ParamMap): UrlParameter {
    if (!!queryParameterMap.get(this.SORT_ASC)) {
      return new UrlParameter(queryParameterMap.get(this.SORT_ASC), [SortType.ASC]);
    }

    if (!!queryParameterMap.get(this.SORT_DESC)) {
      return new UrlParameter(queryParameterMap.get(this.SORT_DESC), [SortType.DESC]);
    }

    return null;
  }

  public createSearchParameters(parameterName: string[], searchTokens: string[]): UrlParameter[] {
    return !searchTokens ? [] : parameterName.map(paramName => new UrlParameter(paramName, searchTokens))
  }

  public convertUrlParametersToString(urlParameters: UrlParameter[]): string {
    if (!urlParameters || urlParameters.length === 0) {
      return "";
    }

    let urlParametersAsString: string = `?${urlParameters[0].field}=${urlParameters[0].value}`;
    for (let i = 1; i < urlParameters.length; i++) {
      urlParametersAsString += `&${urlParameters[i].field}=${encodeURIComponent(urlParameters[i].value.toString())}`;
    }

    return urlParametersAsString;
  }

  public convertUrlParametersToFacets(urlParameters: Params): FacetSelection[] {

    const selectedFacets: FacetSelection[] = Object.keys(urlParameters)
      .filter((property: string) => property.endsWith("Facet"))
      .map((property: string) => (urlParameters[property] + "").split(",")
        .map((value: string) => new FacetSelection(value, 0, property.substring(0, property.length - 5), true)))
      .reduce((allFacets: FacetSelection[], currentFacets: FacetSelection[]) => allFacets.concat(currentFacets), [])

    const unselectedFacets: FacetSelection[] = Object.keys(urlParameters)
      .filter((property: string) => property === "facets")
      .map((property: string) => urlParameters[property].split(","))
      .reduce((allFields: string[], currentFields: string[]) => allFields.concat(currentFields), [])
      .filter((field: string) => !selectedFacets.some(facet => facet.field === field))
      .map((field: string) => new FacetSelection(null, 0, field, false));

    return selectedFacets.concat(unselectedFacets);
  }

  public convertFacetsToUrlParameters(facets: FacetSelection[]): UrlParameter[] {
    return _.chain(facets)
      .groupBy(facet => facet.field)
      .map((facetSelections: FacetSelection[], field: string) => new UrlParameter(`${field}Facet`, facetSelections.map(facet => facet.value)))
      .value();
  }

  public convertSearchParametersToString(searchParameters: UrlParameter[]): string {
    return searchParameters
      .filter(searchParameter => searchParameter.field === 'documentName')
      .map(searchParameter => searchParameter.value)
      .reduce((previousValue: string, currentValue: string) => `${previousValue}${currentValue}`, "")
      .replace(/,/g, " ");
  }

  public createSortParametersString(sortParameterMap: Map<string, SortType>, sortDirection: SortType): string {
    if (!sortParameterMap || sortParameterMap.size === 0) {
      return null;
    }

    return Array.from(sortParameterMap.entries())
      .filter(([fieldName, sortType]) => sortType == sortDirection)
      .map(([fieldName, sortType]) => fieldName)
      .reduce((previousValue: string, currentValue: string, currentIndex: number) => `${previousValue}${currentIndex > 0 ? "," : ""}${currentValue}`, "");
  }

  public createOverviewUrlParameters(searchParameters: UrlParameter[], sortParameter: UrlParameter, facetParameters: UrlParameter[], facetFields: UrlParameter, page: number): Map<string, string> {

    const overviewUrlParameter: any = {};

    searchParameters
      .concat(facetParameters)
      .filter((searchParameter: UrlParameter) => !this.isEmpty(searchParameter))
      .forEach((searchParameter: UrlParameter) => {
        if (!overviewUrlParameter[searchParameter.field]) {
          overviewUrlParameter[searchParameter.field] = [];
        }

        overviewUrlParameter[searchParameter.field].push(searchParameter.value);
      });

    if (!!sortParameter) {
      let parameterName = sortParameter.value == SortType.ASC ? this.SORT_ASC : this.SORT_DESC;
      overviewUrlParameter[parameterName] = sortParameter.field;
    }

    overviewUrlParameter[facetFields.field] = facetFields.value;

    overviewUrlParameter.page = page;
    return overviewUrlParameter;
  }

  equalsParamPage(params: ParamMap, pageable: Pageable): boolean {
    if (!!params.get('page') && params.get('page') === String(pageable.page)) {
      return true;
    }
    return false;
  }

  equalsParamSearchField(params: ParamMap, allParameters: any, searchFieldString: string): boolean {
    if (!!allParameters && !!params) {

      if (!!allParameters[searchFieldString] && !!params.get(searchFieldString)) {
        if (Array.isArray(allParameters[searchFieldString])) {
          return (allParameters[searchFieldString][0].reduce((previous, current) => `${previous},${current}`) === params.get(searchFieldString));
        }
        return allParameters[searchFieldString] === params.get(searchFieldString);
      }

      if (!allParameters[searchFieldString] && !params.get(searchFieldString)) {
        return true;
      }
    }

    return false;
  }

  equalsParamSort(params: ParamMap, sortParameter: UrlParameter): boolean {
    if (!params['sortDesc'] && !params['sortAsc'] && (!sortParameter || (!!sortParameter && sortParameter.field === ''))) {
      return true;
    }

    if (!!params['sortDesc'] && !!sortParameter && sortParameter.field === params['sortDesc']) {
      return true;
    }

    if (!!params['sortAsc'] && !!sortParameter && sortParameter.field === params['sortAsc']) {
      return true;
    }

    return false;
  }

  equalsParamFacets(params: ParamMap, facetSearchParameters: UrlParameter[], facetFieldsParameter: UrlParameter) {
    if (!!facetFieldsParameter) {
      let newFacetSearchParameters: UrlParameter[] = [];
      facetFieldsParameter.value.split(',').forEach(facetField => {

        let paramsValue = params.get(facetField + 'Facet');

        if (!!paramsValue) {
          if (typeof (paramsValue) === 'string') {
            newFacetSearchParameters.push(new UrlParameter(facetField + 'Facet', paramsValue.split(',')));
          }
        }
      });
      return this.compareFacetsParameter(newFacetSearchParameters, facetSearchParameters) ? this.compareFacetsParameter(facetSearchParameters, newFacetSearchParameters) : false
    }
    return true;
  }

  compareFacetsParameter(newFacetSearchParameters: UrlParameter[], oldFacetSearchParameters: UrlParameter[]): boolean {
    if (!oldFacetSearchParameters[0] && !newFacetSearchParameters[0]) {
      return true;
    }

    if (!((!!oldFacetSearchParameters[0] && !!newFacetSearchParameters[0]) &&
      (oldFacetSearchParameters.length === newFacetSearchParameters.length))
    ) {
      return false;
    }

    newFacetSearchParameters.forEach(facetSearchParameter => {
      if (!((!!facetSearchParameter.value[0]) && newFacetSearchParameters.some(newFacetSearchParameter => facetSearchParameter.field === newFacetSearchParameter.field))) {
        return false;
      }

      facetSearchParameter.value.forEach(facet => {
        newFacetSearchParameters
          .filter(newFacetSearchParameter => facetSearchParameter.field === newFacetSearchParameter.field)
          .forEach(newFacetSearchParameter => {
            if (!newFacetSearchParameter.value[0] || !newFacetSearchParameter.value.some(newFacet => newFacet === facet)) {
              return false;
            }
          })
      });
    });

    return true;

  }

  public convertRouteParamsToSearchParameter(params: ParamMap, searchFieldString: string): UrlParameter[] {
    let searchParamString = params.get(searchFieldString);
    if (!!searchParamString) {
      return [new UrlParameter(searchFieldString, searchParamString.split(','))];
    } else {
      return [new UrlParameter(searchFieldString, [''])];
    }
  }

  public convertRouteParamsToFacetParameters(params: ParamMap, facetFieldsParameter: UrlParameter, setFacet: Function) {
    let newFacetSearchParameters: UrlParameter[] = [];
    facetFieldsParameter.value.split(',').forEach(facetField => {
      let facetParamString = params.get(String(facetField) + 'Facet');
      if (!!facetParamString) {
        newFacetSearchParameters.push(new UrlParameter(String(facetField) + 'Facet', facetParamString.split(',')));
        facetParamString.split(',').forEach((facet: string) => setFacet(String(facetField), facet));
      }

    });
    return newFacetSearchParameters;
  }

  public convertRouteParamsToSortParameter(params: ParamMap, setSorting: Function): UrlParameter {
    let sortParamString = params.get(this.SORT_DESC);
    if (!!sortParamString) {
      setSorting(sortParamString, SortType.DESC);
      return new UrlParameter(sortParamString, [SortType.DESC]);
    }

    sortParamString = params.get(this.SORT_ASC);
    if (!!sortParamString) {
      setSorting(sortParamString, SortType.ASC);
      return new UrlParameter(sortParamString, [SortType.ASC]);
    }
  }

  public convertUserFilterParamToUrlParam(userFilterParameter: UserFilterParameter): UrlParameter {
    const key: string = userFilterParameter.filterParameterName;
    const value: string = JSON.parse(userFilterParameter.filterParameterValue);
    return new UrlParameter(key, value);
  }
}

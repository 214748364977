import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { lastValueFrom, Observable, tap } from 'rxjs';
import { UserFilterResponse } from '../../../model/user-filter/user-filter-response';
import { UserFilterBackendService } from '../../../service/user-filter/user-filter-backend.service';
import { UserFilterInterfaceService } from '../../../service/user-filter/user-filter-interface.service';
import { UserFilterChanged } from '../../../model/user-filter/user-filter-changed';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmService } from '../../../service/confirm.service';
import { UserFilterSaveRequest } from '../../../model/user-filter/user-filter-save-request';
import { UserFilterParameter } from '../../../model/user-filter/user-filter-parameter';

@Component({
  selector: 'app-user-filter-save',
  templateUrl: './user-filter-save.component.html',
  styleUrl: './user-filter-save.component.less'
})
export class UserFilterSaveComponent implements OnInit {

  public filterName: string;
  private _filter: UserFilterChanged;



  constructor(
    private modal: NgbActiveModal,
    private confirmService: ConfirmService,
    private userFilterBackendService: UserFilterBackendService,
    private userFilterInterfaceService: UserFilterInterfaceService
  ) { }

  ngOnInit(): void {
    this.userFilterInterfaceService.userFilterChanged.subscribe(f => this.initializeFilter(f));
  }

  private initializeFilter(userFilterChanged: UserFilterChanged): void {
    this._filter = userFilterChanged;
  }

  async clickOk() {
    const filters: UserFilterResponse[] = await this.readFilters();
    const existingFilters: UserFilterResponse[] = _(filters).value();
    const existingFilterNames: string[] = _(existingFilters).map(f => f.filterName).value();
    const filterName: string = this.filterName;

    let overrideFilter: boolean = false;
    const matchingFilterCount: number = existingFilterNames.filter(f => filterName === f).length;
    const filterExists: boolean = matchingFilterCount >= 1;
    if (filterExists) {
      overrideFilter = await this.askToConfirmOverwriting();
    }

    if (filterExists && !overrideFilter) {
      this.modal.close();
      return;
    }

    const filterId: string = _(existingFilters).find(f => f.filterName === filterName)?.filterId;
    const filterType: string = this._filter.filterType;
    const defaultFilter: boolean = false;
    const userFilterParameters: UserFilterParameter[] = this._filter.filterParameters;
    const request: UserFilterSaveRequest = new UserFilterSaveRequest(filterId, filterName, filterType, userFilterParameters, defaultFilter);

    await lastValueFrom(this.userFilterBackendService.saveFilter(request));
    this.modal.close();
  }

  clickCancel(): void {
    this.modal.close();
  }

  private async readFilters(): Promise<UserFilterResponse[]> {
    const filterType: string = this._filter.filterType;
    const filtersResponse: Observable<UserFilterResponse[]> = this.userFilterBackendService.listFilters(filterType);
    return lastValueFrom(filtersResponse);
  }


  private async askToConfirmOverwriting() {
    this.modal.close();
    const confirmed = await this.confirmService.confirm('user.filter.overwrite.dialog.title', 'user.filter.overwrite.dialog.message', 'button.yes', 'button.cancel');
    return confirmed;
  }
}

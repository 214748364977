<app-overview-view [tableColumns]="tableColumns"
    [title]="'headline.template.view.material.list' | translate"
    [searchFunction]="searchFunction"
    [facetFields]="facetFields"
    [rowClicked]="rowClicked"
    [addFunction]="addFunction"
    [addButtonSuffix]="'.document'"
    [addAllowed]="addAllowed && !(isNotAdvancedUser | async)"
    [leftCustomButtons]="leftCustomButtons"
    [overviewValueTemplate]="overviewValueTemplate"
    [customParameters]="customParameters"
    searchFields="templateViewMaterialName"
    typeaheadFieldLabelPrefix="typeaheadFieldLabelPrefix"
    predefinedValueTemplate="documentOverviewValueTemplate"
    [placeholder]="'placeholder.search.text' | translate"
    filterType="document_creation_overview">
</app-overview-view>

<ng-template #overviewValueTemplate let-r="result" let-t="term">
  <small>
    Material: <ngb-highlight [result]="r.docBridgeMaterialName | localizedStringsFilter" [term]="t"></ngb-highlight>
    (<ngb-highlight [result]="r.docBridgeMaterialNumber" [term]="t"></ngb-highlight>)
    | Template View: (<ngb-highlight [result]="r.docBridgeTemplateViewName | localizedStringsFilter" [term]="t"></ngb-highlight>)
  </small>
</ng-template>

<ng-template #materialIdTemplate let-row>
    <span class="material-id-template" (click)="$event.stopPropagation()">
      <a class="link" href="#" [routerLink]="['/material/'+row.docBridgeMaterialNumber]">{{row.docBridgeMaterialNumber}}&nbsp;</a>
      <a class="link" href="#" [routerLink]="['/material/'+row.docBridgeMaterialNumber]" target="_blank">
        <fa-icon [styles]="" icon="external-link-alt" class="fa-sg"></fa-icon>
      </a>
    </span>
</ng-template>

<ng-template #leftCustomButtons>
  <button (click)="copySearchFunction()" id="btnCopyUrl" class="btn btn-primary copy-search" 
    title="{{'button.copySearchLink' | translate}}">
    <fa-icon icon="clipboard" class="fa-solid"></fa-icon>
  </button>
</ng-template>
<div>
  <nav class="sidebar-sticky">
    <ul class="nav flex-column">
      <li class="nav-item">
        <a class="nav-link" id="user-dashboard-link" routerLink="/dashboard" routerLinkActive="router-link-active">
          <fa-icon icon="rocket"></fa-icon> {{ 'dashboard' | translate }}
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="user-materials-link" [ngClass]="{ 'router-link-active' : materialsActive }" [routerLink]="['/materials']"
            [queryParams]="{typeFacet: 'PRD', facets: 'type,status,goo,productType,sdsVisibility,categoriesAssigned,documentsAssigned', page:0}" >
          <fa-icon icon="box-open"></fa-icon> {{ 'materials' | translate }}
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="user-documents-link" [ngClass]="{ 'router-link-active' : documentsActive }" routerLink="/documents">
          <fa-icon icon="book"></fa-icon> {{ 'documents' | translate }}
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="user-formulations-link" routerLink="/formulations" routerLinkActive="router-link-active">
          <fa-icon icon="flask"></fa-icon> {{ 'formulations' | translate }}
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="user-pho-link" [ngClass]="{ 'router-link-active' : phoActive }" routerLink="/product-hierarchy-objects">
          <fa-icon icon="project-diagram"></fa-icon> {{ 'label.pho.sidebar' | translate }}
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="user-categories-link" routerLink="/categories" routerLinkActive="router-link-active">
          <fa-icon icon="sitemap"></fa-icon> {{ 'categories' | translate }}
        </a>
      </li>
      <div *ngIf="(isAdvanced | async) || (isAdmin | async)">
        <li class="nav-item-admin">
          <a class="nav-link" id="administration-advanced-link" [ngClass]="{ 'router-link-active' : advancedToolsActive }" routerLink="/adminstration/advanced">
            <fa-icon icon="gears"></fa-icon> {{ 'advanced' | translate }}
          </a>
        </li>
      </div>
    </ul>

    <div *ngIf="(isAdmin | async)">
    <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
      <span>{{ 'adminstration' | translate }}</span>
      <a class="d-flex align-items-center text-muted" href="#">
        <span data-feather="plus-circle"></span>
      </a>
    </h6>
    <ul class="nav flex-column mb-2">
      <li class="nav-item-admin">
        <a class="nav-link" id="administration-category-link" routerLink="/adminstration/category" routerLinkActive="router-link-active">
          <fa-icon icon="sitemap"></fa-icon> {{ 'categories' | translate }}
        </a>
      </li>
      <li class="nav-item-admin">
        <a class="nav-link" id="administration-material-link" [ngClass]="{ 'router-link-active' : materialsAdministrationActive }" [routerLink]="['/adminstration/material']"
            [queryParams]="{typeFacet: 'THIRD_PARTY_PRODUCT,BASE_INGREDIENT', facets: 'type,status,goo,productType,sdsVisibility,categoriesAssigned,documentsAssigned', page:0}" >
          <fa-icon icon="box-open"></fa-icon> {{ 'materials' | translate }}
        </a>
      </li>
      <li class="nav-item-admin">
        <a class="nav-link" id="administration-view-link" routerLink="/adminstration/view" routerLinkActive="router-link-active">
          <fa-icon icon="eye"></fa-icon> {{ 'views' | translate }}
        </a>
      </li>
      <li class="nav-item-admin">
        <a class="nav-link" id="administration-attribute-link" routerLink="/adminstration/attribute" routerLinkActive="router-link-active">
          <fa-icon icon="sliders-h"></fa-icon> {{ 'attributes' | translate }}
        </a>
      </li>
      <li class="nav-item-admin">
        <a class="nav-link" id="administration-unit-link" routerLink="/adminstration/unit" routerLinkActive="router-link-active">
          <fa-icon icon="balance-scale"></fa-icon> {{ 'units' | translate }}
        </a>
      </li>
      <li class="nav-item-admin">
        <a class="nav-link" id="administration-language-link" routerLink="/adminstration/language" routerLinkActive="router-link-active">
          <fa-icon icon="flag"></fa-icon> {{ 'languages' | translate }}
        </a>
      </li>
    </ul>
    </div>

  </nav>
</div>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { lastValueFrom, map, Observable, Subject, tap, timeout } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserFilterChanged } from '../../model/user-filter/user-filter-changed';
import { UserFilterResponse } from '../../model/user-filter/user-filter-response';
import { UserFilterSaveRequest } from '../../model/user-filter/user-filter-save-request';
import { BackendValidationService } from '../form-validation/backend-validation.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UserFilterBackendService {

  private restUrl: string = `${environment.restUrl}/overviewFilter`;
  private _userFilterSaved: Subject<UserFilterResponse> = new Subject();
  private _requestTimeout: number = 10_000;

  constructor(
    private httpClient: HttpClient,
    private backendValidationService: BackendValidationService,
    private translateService: TranslateService
  ) { }

  public get userFilterSaved(): Subject<UserFilterResponse> {
    return this._userFilterSaved;
  }


  public listFilters(filterType: string): Observable<UserFilterResponse[]> {
    const url: string = `${this.restUrl}/listFilters`;
    const params: HttpParams = new HttpParams().set("filterType", filterType);
    return this.httpClient.get<UserFilterResponse[]>(url, { params: params }).pipe(this.backendValidationService.renderErrorMessages());
  }

  public saveFilter(userFilterSaveRequest: UserFilterSaveRequest): Observable<UserFilterResponse> {
    const url: string = `${this.restUrl}/saveFilter`;
    const successMessage: string = this.translateService.instant("user.filter.save.success.message");
    return this.httpClient.post<UserFilterResponse>(url, userFilterSaveRequest)
      .pipe(this.backendValidationService.renderMessages(successMessage))
      .pipe(tap(ufr => this._userFilterSaved.next(ufr)));
  }

  public listFiltersAsPromise(filterType: string): Promise<UserFilterResponse[]> {
    const result: Observable<UserFilterResponse[]> = this.listFilters(filterType).pipe(timeout(this._requestTimeout))
    return lastValueFrom(result);
  }

}


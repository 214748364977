import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { UserFilterChanged } from '../../model/user-filter/user-filter-changed';
import { UserFilterResponse } from '../../model/user-filter/user-filter-response';
import { UserFilterBackendService } from '../../service/user-filter/user-filter-backend.service';
import { UserFilterInterfaceService } from '../../service/user-filter/user-filter-interface.service';
import { UserFilterSaveComponent } from './user-filter-save/user-filter-save.component';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrl: './user-filter.component.less'
})
export class UserFilterComponent implements OnInit {

  selectedFilter: UserFilterResponse;
  userFilters: UserFilterResponse[];
  isEditBarCollapsed: boolean = true;

  constructor(
    private userFilterBackendService: UserFilterBackendService,
    private userFilterInterfaceService: UserFilterInterfaceService,
    private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.userFilterInterfaceService.filterTypeChanged.subscribe(filterType => this.loadFiltersByFilterType(filterType));
    this.userFilterInterfaceService.userFilterChanged.subscribe(userFilterChanged => this.loadFiltersByUserFilterChanged(userFilterChanged));
    this.userFilterInterfaceService.userFilterChanged.subscribe(f => this.clearFilter(f));
    this.userFilterBackendService.userFilterSaved.subscribe(ufr => this.onUserFilterSaved(ufr))
  }

  clearFilter(userFilterChanged: UserFilterChanged): void {
    if (!userFilterChanged || !userFilterChanged.filterName) {
      this.selectedFilter = null;
    }
  }

  private async onUserFilterSaved(savedUserFilterResponse: UserFilterResponse) {
    const filterType: string = savedUserFilterResponse.filterType;
    const currentFilters: UserFilterResponse[] = await this.userFilterBackendService.listFiltersAsPromise(filterType);
    this.userFilters = currentFilters;
    this.onSelectedFilterChanged(savedUserFilterResponse);
  }

  onSelectedFilterChanged(userFilterResponse: UserFilterResponse): void {
    this.selectedFilter = userFilterResponse;
    const userFilterChanged: UserFilterChanged = this.toUserFilterChanged(userFilterResponse);
    this.userFilterInterfaceService.changeFilter(userFilterChanged);
  }


  toggleEditBar() {
    this.isEditBarCollapsed = !this.isEditBarCollapsed;
  }

  private loadFiltersByUserFilterChanged(userFilterChanged: UserFilterChanged): void {

    if (!userFilterChanged || !userFilterChanged.filterType) {
      return;
    }

    this.userFilterBackendService.listFilters(userFilterChanged?.filterType)
      .subscribe(userFilters => this.initializeUserFilters(userFilters));
  }

  private loadFiltersByFilterType(filterType: string): void {

    if (!filterType) {
      return;
    }

    this.userFilterBackendService.listFilters(filterType)
      .subscribe(userFilters => this.initializeUserFilters(userFilters));
  }


  private initializeUserFilters(userFilters: UserFilterResponse[]): void {
    this.userFilters = userFilters;
  }


  addFilter(): void {
    const modalRef: NgbModalRef = this.modalService.open(UserFilterSaveComponent, { windowClass: 'modal-style-class' });
  }

  public compareFn(a, b): boolean {
    if (!a && !b) {
      return true;
    }

    return !!a && !!b && a.filterId === b.filterId;
  }

  toUserFilterChanged(userFilterResponse: UserFilterResponse): UserFilterChanged {
    return new UserFilterChanged(userFilterResponse.filterId, userFilterResponse.filterType, userFilterResponse.filterName, userFilterResponse.filters);
  }

}

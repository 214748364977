<div class="container-fluid pb-3">

  <h2 class="display-6 fw-normal">{{ category.name | localizedStringsFilter }}</h2>

  <div class="row">

    <div class="col-8">

      <div class="row">
        <div class="col-6 form-group">
          <localized-string-edit [localizedStrings]="category.name" [label]="'label.administration.category.name' | translate" [readonly]="disabledInputs"></localized-string-edit>
        </div>
        <div class="col-6 form-group">
          <localized-string-edit [localizedStrings]="category.shortName" [label]="'label.administration.category.short_name' | translate" [readonly]="disabledInputs"></localized-string-edit>
        </div>
      </div>

      <div class="row">
        <div class="col-6">

          <div class="row">
            <div class="col-12 form-group">
              <label for="descritpion">{{ 'label.administration.category.description' | translate }}</label>
              <textarea type="text" class="form-control" id="descritpion" name="descritpion" [ngModel]="category.description" [disabled]="disabledInputs"></textarea>
            </div>
            <div class="col-12 form-group">
              <label for="type">{{ 'label.administration.category.type' | translate }}</label>
              <select class="form-select" id="type" required name="type" [ngModel]="category.type" [disabled]="disabledInputs">
                <option *ngFor="let type of categoryType | enumToArray" value="{{type}}">
                  {{ 'label.type.' + type  | lowercase | camelCase | translate }}</option>
              </select>
            </div>
            <div class="col-12 form-group">
              <label for="parent">{{ 'label.administration.category.parent' | translate }}</label>
              <input type="text" class="form-control" id="parent" name="parent" [ngModel]="categoryFormatterInput(category.parent)" [disabled]="disabledInputs" >
            </div>
          </div>
        </div>

        <div class="col-6">

          <div class="row">

            <div class="col-12 form-group">
              <localized-string-edit
                id="marketing-description-lse"
                inputType="editor"
                [localizedStrings]="category.marketingDescription"
                [label]="'label.administration.category.marketing-description'|translate"
                [englishFixed]= false
                [englishRequired]=false
                [showCharactersLeft]=true
                [maxStringLength]="255"
                [readonly]="disabledInputs"
              >
              </localized-string-edit>
            </div>

            <div class="col-12 form-group">
              <label>{{ 'label.administration.category.regional_validity' | translate }}</label>
              <div class="" *ngIf="category.regionalValidity.length === 0">-</div>
              <div class="value-list" *ngIf="category.regionalValidity.length > 0">
                <div *ngFor="let value of category.regionalValidity; let i = index" >
                  <input *ngIf="value" class="form-control" value="{{ value?.name | localizedStringsFilter }}" [disabled]="true">
                </div>
              </div>
            </div>

            <div class="col-12 form-group">
              <label>{{ 'label.administration.category.catalog_validity' | translate }}</label>
              <div class="" *ngIf="category.catalogValidity.length === 0">-</div>
              <div class="value-list" *ngIf="category.catalogValidity.length > 0">
                <div *ngFor="let value of category.catalogValidity; let i = index" >
                  <input *ngIf="value" class="form-control" value="{{ value?.name | localizedStringsFilter }}" [disabled]="true">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <localized-string-list-edit
            [label]="'label.synonyms' | translate"
            [readonly]="disabledInputs"
            [localizedStrings]="category.synonyms"
            inputType="textarea" >
          </localized-string-list-edit>
        </div>
      </div>
    </div>

    <div class="col-4">
      <div class="row">
        <div class="col-12 form-group">
          <label for="attribute">{{ 'label.administration.category.attributes' | translate }}</label>
          <table *ngIf="category.categoryAttributes?.length !== 0" class="table mb-0">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Unit</th>
                <th scope="col">Type</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let categoryAttribute of category.categoryAttributes">
                <td>{{ categoryAttribute.attribute.name | localizedStringsFilter }}</td>
                <td>{{ categoryAttribute.attribute?.unit?.name | localizedStringsFilter }}</td>
                <td>{{ categoryAttribute.attribute?.type }}</td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="category.categoryAttributes?.length === 0">-</div>
        </div>
      </div>
    </div>

  </div>

  <div class="row mb-3">
    <div class="col-auto">
      <button type="submit" class="btn btn-primary" id="btnCategoryReturn"
        (click)="return()">{{ 'button.return' | translate }}</button>
    </div>
  </div>

</div>

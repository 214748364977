import { Injectable } from '@angular/core';
import { FacetSelection } from '../../model/facet-selection';
import { OverviewService } from './overview.service';
import { UrlParameter } from '../../model/parameter/url-parameter';
import * as _ from 'lodash';
import { UserFilterChanged } from '../../model/user-filter/user-filter-changed';
import { OverviewUserFilterService } from './overview-user-filter.service';

@Injectable({
  providedIn: 'root'
})
export class OverviewFacetService {

  constructor(
    private readonly overviewService: OverviewService,
    private readonly overviewUserFilterService: OverviewUserFilterService
  ) { }

  private _facets: Map<string, FacetSelection[]> = new Map<string, FacetSelection[]>();

  public get facets(): Map<string, FacetSelection[]> {
    return this._facets;
  }

  public set facets(value: Map<string, FacetSelection[]>) {
    this._facets = value;
  }

  public removeFacetFieldNamePostfix(facetFieldName: string): string {
    return facetFieldName.replace(/Facet$/, "");
  }

  public setFacet(facetField: any, facet: string): void {
    const facetExists: boolean = !!this._facets.get(facetField);
    const facetValueExists: boolean = facetExists && this._facets.get(facetField).some((facetSelection: FacetSelection) => facetSelection.value === facet);

    if (facetValueExists) {
      this._facets.get(facetField)
        .filter((facetSelection: FacetSelection) => facetSelection.value === facet)
        .forEach((facetSelection: FacetSelection) => facetSelection.selected = true);
    } else {
      if (!this._facets.get(facetField)) {
        this._facets.set(facetField, [])
      }
      this._facets.get(facetField).push(new FacetSelection(facet, 0, facetField, true))
    }
  }

  private updateFacetSearchParameter(urlParam: UrlParameter): void {
    const facetField: string = this.removeFacetFieldNamePostfix(urlParam.field);
    const facetValues: string[] = !_.isArray(urlParam.value) ? [urlParam.value] : urlParam.value;

    facetValues.forEach(facetValue => {
      this.setFacet(facetField, facetValue);
    });


  }

  public updateFacetSearchParameters(userFilterChanged: UserFilterChanged): UrlParameter[] {
    const updatedFacetSearchParameters: UrlParameter[] = this.overviewUserFilterService.updateFacetSearchParameters(userFilterChanged);

    if ( _.isEmpty(updatedFacetSearchParameters)) {
      this.resetFacets();
      return [];
    }

    updatedFacetSearchParameters.forEach(urlParam => this.updateFacetSearchParameter(urlParam));

    return updatedFacetSearchParameters;
  }

  resetFacets() {
    this.facets.forEach((facets: FacetSelection[]) => {
      facets.forEach((facet: FacetSelection) => {
        facet.selected = false
      })
    })

  }

}

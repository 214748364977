<div class="container-fluid">
  <div class="row">
    <div class="col-6">

      <select id="user-filter-dropdown" class="form-select edit-menu-drowdown"
      [compareWith]="compareFn"
        [ngModel]="selectedFilter"
        (ngModelChange)="onSelectedFilterChanged($event)">
        <option id="user-filter-dropdown-no-filter-option" *ngIf="!selectedFilter" [ngValue]="null"></option>
        <option [id]="'user-filter-dropdownoption-' + userFilter.filterName" *ngFor="let userFilter of userFilters "
          [ngValue]="userFilter">{{ userFilter.filterName }}</option>
      </select>

    </div>
    <div class="col-3">
      <button class="btn btn-primary"
      id="add-user-filter"
      (click)="addFilter()">{{ 'button.add' | translate}}</button>
    </div>
    <div class="col-3" id="edit-user-filter">
      <button class="btn btn-primary"
      (click)="toggleEditBar()">{{ 'button.edit' | translate}}</button>
    </div>

  </div>
  <div class="row">
    <div class="col">


      <div [collapse]="isEditBarCollapsed">
        <div class="container"></div>

        <div class="row button-bar">
          
          <div class="col"><button id="rename-user-filter" class="btn btn-primary">Rename</button>
          </div>
          <div class="col"><button id="make-default-user-filter" class="btn btn-primary">Make Default</button>
          </div>
          <div class="col"><button id="remove-user-filter" class=" btn btn-primary">Remove</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Category } from "../model/category";
import { Pageable } from "../model/pageable";
import { Observable } from "rxjs";
import { Page } from "../model/pim-response/page";
import { Document } from "../model/document/document";
import { environment } from "../../environments/environment";
import { Constants } from "../constants/constants";
import { ProductHierarchyObject } from "../model/product-hierarchy-object/product-hierarchy-object";

@Injectable({
  providedIn: 'root'
})
export class ProductHierarchyObjectUsageService {

  constructor(private httpClient: HttpClient) { }

  public findOverallUsage(pho: ProductHierarchyObject): Observable<boolean> {
    return this.httpClient.get<boolean>(environment.restUrl + '/producthierarchyobject/usage/overall-usage/' + pho.id, Constants.httpOptionsHidingLoadingIndicator);
  }

  public findDocumentUsage(pho: ProductHierarchyObject): Observable<boolean> {
    return this.httpClient.get<boolean>(environment.restUrl + '/producthierarchyobject/usage/document-usage/' + pho.id, Constants.httpOptionsHidingLoadingIndicator);
  }

  public findAssignedDocuments(pho: ProductHierarchyObject, pageable: Pageable): Observable<Page<Document>> {
    return this.httpClient.get<Page<Document>>(environment.restUrl + '/producthierarchyobject/usage/document/' + pho.id + '?' + pageable.toUrlString());
  }
}

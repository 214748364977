import { Input, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component(
    {
        selector: 'confirm-component',
        templateUrl: './confirm.component.html'
    }
)
export class ConfirmComponent {
    @Input() 
    public title;

    @Input() 
    public text;

    @Input() 
    public yes;

    @Input() 
    public no;
    
    @Input() 
    public cancel;

    constructor(public modal: NgbActiveModal) { }
}
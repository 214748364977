import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Page } from '../../../../model/pim-response/page';
import { TableColumn } from '../../../../model/table-column';
import { TranslateService } from '@ngx-translate/core';
import { TableService } from '../../../../service/table.service';
import { LocalizedString } from '../../../../model/localized-string';
import { TemplateViewMaterialSearchService } from '../../../../service/template-view-material-search.service';
import { OverviewTemplateViewMaterial } from '../../../../model/document-creation/overview-template-view-material';
import { LocalizedStringsFilterPipe } from '../../../../pipes/localized-strings-filter.pipe';
import InjectIsNotAdvancedUser from '../../../../decorator/inject-is-not-advanced-user.decorator';
import { OverviewService } from '../../../../service/overview/overview.service';
import { ClipboardService } from '../../../../service/clipboard.service';

@Component({
  selector: 'app-overview',
  templateUrl: './document-creation-overview.component.html',
  styleUrls: ['./document-creation-overview.component.less'],
})
export class DocumentCreationOverviewComponent implements OnInit, OnDestroy {

  @InjectIsNotAdvancedUser
  public isNotAdvancedUser: Observable<boolean>;

  @ViewChild('materialIdTemplate', {static: true})
  private readonly materialIdTemplate: TemplateRef<any>;

  constructor(
    private readonly router: Router,
    private readonly tableService: TableService,
    private readonly overviewService: OverviewService,
    private readonly translateService: TranslateService,
    private readonly localizedStringsFilterPipe: LocalizedStringsFilterPipe,
    private readonly templateViewMaterialSearchService: TemplateViewMaterialSearchService,
    private readonly clipboardService: ClipboardService
  ) {
  }

  @Input()
  public addAllowed = true;

  @Input()
  public customParameters: string;

  @Input()
  public showRemoveButton = false;
  public addUrl = 'adminstration/document-creation/select'
  public editUrl = 'adminstration/document-creation/edit/properties'

  public readonly typeaheadFieldLabelPrefix: string = 'label.template.view.material.overview.typeahead.'

  public facetFields: string;

  public tableColumns: TableColumn<any>[];

  public searchFunction: (queryParameters: Map<string, string>) => Observable<Page<OverviewTemplateViewMaterial>> =
    (queryParameters: Map<string, string>) => this.executeSearch(queryParameters);

  public rowClicked: (overviewTemplateViewMaterial: OverviewTemplateViewMaterial) => void = (overviewTemplateViewMaterial: OverviewTemplateViewMaterial) => {
    const params: Params = {
      material: overviewTemplateViewMaterial.docBridgeMaterialNumber,
      templateView: overviewTemplateViewMaterial.docBridgeTemplateViewId,
      context: 'bridge',
    }
    return this.router.navigate([this.editUrl], {queryParams: params})
  }

  public addFunction: () => void = () => this.router.navigateByUrl(this.addUrl);
  public copySearchFunction: () => void = () => this.clipboardService.copy(window.location.origin + "/#" + this.router.url);

  ngOnInit(): void {
    this.initializeTableColumns();
    this.facetFields = this.tableColumns
      .filter(c => c.isFilterable)
      .map(c => c.field).join(',');
  }

  ngOnDestroy(): void {
    this.overviewService.resetPage();
  }

  public statusValueFunction: (value: string) => string = (value: any) => {
    let returnValue: string = '';
    if (typeof (value) === 'string') {
      this.translateService.get('document.' + value.toLowerCase()).subscribe((newValue: string) => returnValue = newValue)
    }
    return returnValue;
  }

  public executeSearch(queryParameters: any): Observable<Page<OverviewTemplateViewMaterial>> {
    return this.templateViewMaterialSearchService.search(queryParameters);
  }

  private initializeTableColumns(): void {
    this.tableColumns = [
      new TableColumn('docBridgeMaterialNumber',
        this.translateService.get('label.materialnumber'), null,
        false, true, null, false, this.materialIdTemplate),
      new TableColumn('docBridgeMaterialType',
        this.translateService.get('label.material.type'),
        (docBridgeMaterialType: string) => this.tableService.stringValueFunction(docBridgeMaterialType), true, true),
      new TableColumn('docBridgeMaterialName',
        this.translateService.get('label.materialname'),
        (docBridgeMaterialName: LocalizedString[]) => this.localizedStringsFilterPipe.transform(docBridgeMaterialName), false, true),
      new TableColumn('docBridgeMaterialProductType',
        this.translateService.get('label.material.product_type'),
        (docBridgeMaterialProductType: string) => this.tableService.stringValueFunction(docBridgeMaterialProductType), true, true),
      new TableColumn('type',
        this.translateService.get('label.document.type'),
        (documentType: LocalizedString[]) => this.localizedStringsFilterPipe.transform(documentType), true, true),
      new TableColumn('docBridgeTemplateViewName',
        this.translateService.get('label.administration.document_creation.select.templateview'),
        (docBridgeTemplateViewName: LocalizedString[]) => this.localizedStringsFilterPipe.transform(docBridgeTemplateViewName), true, true),
      new TableColumn('docBridgeTemplateViewRealmName',
        this.translateService.get('label.view.realm'),
        (realmName: LocalizedString[]) => this.localizedStringsFilterPipe.transform(realmName), true, true),
      new TableColumn('status',
        this.translateService.get('label.status'),
        (status: string) => this.statusValueFunction(status), true, true, (status: string) => this.statusValueFunction(status), true),
    ];
  }
}

<form #f="ngForm">

  <div class="row">
    <div class="form-group col-4">
      <string-input-edit id="pho-properties-internalname" label="{{'label.pho.internal_name'|translate}}"
        [required]="true"
        [readonly]="(isReadOnlyUser | async)"
        [(inputValue)]="productHierarchyObjectService.currentProductHierarchyObject.internalName"></string-input-edit>
    </div>
    <div class="form-group col-8">
      <localized-string-list-edit
        id="pho-properties-synonyms"
        [label]="'label.synonyms' | translate"
        [localizedStrings]="productHierarchyObjectService.currentProductHierarchyObject?.synonyms"
        inputType="textarea" >
      </localized-string-list-edit>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-4">
      <localized-string-edit
        id="pho-properties-marketingname"
        [readonly]="(isReadOnlyUser | async)"
        [localizedStrings]="productHierarchyObjectService.currentProductHierarchyObject?.marketingName"
        [label]="'label.pho.marketing_name' | translate">
      </localized-string-edit>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-4">
      <typeahead
          [inputLabel]="'label.pho.realm' | translate"
          [inputName]="'realm'"
          [inputRequired]="true"
          [inputDisabled]="(isReadOnlyUser | async)"
          [(model)]="productHierarchyObjectService.currentProductHierarchyObject.realm"
          [typeaheadFunction]="realmTypeahead"
          [resultFormatter]="realmTypeaheadFormatter"
          [inputFormatter]="realmTypeaheadFormatter">
      </typeahead>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-4">
      <enumeration-edit [label]="'label.pho.enumeration.type' | translate"
        [convertEmptyStringToNull]="true"
        [readonly]="(isReadOnlyUser | async)"
        [enumerationValues]="productHierarchyObjectTypes"
        [(selectedValue)]="productHierarchyObjectService.currentProductHierarchyObject.type">
      </enumeration-edit>
    </div>
  </div>

  <div class="row form-group">
    <div class="form-group col-4">
      <typeahead
          [inputLabel]="'label.pho.owner' | translate"
          [inputName]="'owner'"
          [inputRequired]="true"
          [inputDisabled]="(isReadOnlyUser | async)"
          [(model)]="productHierarchyObjectService.currentProductHierarchyObject.owner"
          [typeaheadFunction]="ownerTypeAhead"
          [resultFormatter]="ownerTypeAheadFormatter"
          [inputFormatter]="ownerTypeAheadFormatter">
      </typeahead>
    </div>
  </div>

</form>

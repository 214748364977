<div class="row paddig-top" >
  <div class="col-4"> <!-- First column -->
    <div>
      <h3>{{'label.pho.usage.documents'|translate}}</h3>
      <app-table [page]="documents" (rowClick)="navigateToDocument($event)" (pageableHandler)="switchDocumentPage($event)">
        <app-table-column name="{{'label.pho.usage.id'|translate}}" field="id">
        </app-table-column>
        <app-table-column name="{{'label.pho.usage.name'|translate}}" field="name"
                          [valueFunction]="getLocalizedStringsValue" displayLinkIcon="true"></app-table-column>
      </app-table>
    </div>
  </div>
</div>

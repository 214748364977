import { OnInit, Output, Component, Input, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { FacetSelection } from '../../../model/facet-selection';
import { TableColumnComponent } from '../column/table-column.component';
import { OverviewFacetService } from '../../../service/overview/overview-facet.service';

@Component({
  selector: 'app-table-facet',
  templateUrl: './table-facet.component.html',
  styleUrls: ['./table-facet.component.less']
})
export class TableFacetComponent implements OnInit {

  @Output()
  public facetsChange = new EventEmitter<TableColumnComponent>();

  @Input()
  public column: TableColumnComponent;

  public searchString: string = '';

  constructor(private readonly overviewService: OverviewFacetService,

  ) { }

  ngOnInit(): void {
    if (!this.overviewService.facets.get(this.column.field)) {
      this.overviewService.facets.set(this.column.field, []);
    }
  }

  public getSelectedFacets(): FacetSelection[] {
    return this.overviewService.facets.get(this.column.field).filter(facet => facet.selected);
  }

  public getSelectableFacets(): FacetSelection[] {
    return this.overviewService.facets.get(this.column.field).filter(facet => !facet.selected && this.matchesSearchstring(facet));
  }

  private matchesSearchstring(facet: FacetSelection): boolean {
    if (!this.searchString) {
      return true;
    }

    let formattedFacetValue: string = facet.value;

    if (!!this.column.facetFormatting) {
      formattedFacetValue = this.column.facetFormattingFunction(facet.value)
    }

    return formattedFacetValue.toUpperCase().includes(this.searchString.toUpperCase());
  }

  public selectFacet(facet: FacetSelection) {
    if (!facet.selected) {
      facet.selected = true;
    }
  }

  public removeFacet(facet: FacetSelection) {
    if (facet.selected) {
      facet.selected = false;
    }
  }

  public openChange(isOpened: boolean, isConfirmation: boolean): void {
    if (isOpened && !isConfirmation) {
      return;
    }

    if (!isOpened && isConfirmation) {
      this.confirmFacetSelection();
      this.searchString = '';
      return;
    }

  }

  private confirmFacetSelection(): void {
    this.facetsChange.emit(this.column);
  }

}

import { UserFilterParameter } from "./user-filter-parameter";

export class UserFilterSaveRequest {

  
  constructor(
    public filterId: string,
    public filterName: string,
    public filterType: string,
    public filterParameters: UserFilterParameter[],
    public defaultFilter: boolean) {
  }
}
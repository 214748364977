import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { UrlParameter } from '../../model/parameter/url-parameter';
import { SortType } from '../../model/sort-type';
import { UserFilterChanged } from '../../model/user-filter/user-filter-changed';
import { UserFilterParameter } from '../../model/user-filter/user-filter-parameter';
import { UrlParameterService } from '../url-parameter.service';
import { UserFilterInterfaceService } from '../user-filter/user-filter-interface.service';

@Injectable({
  providedIn: 'root'
})
export class OverviewUserFilterService {

  constructor(
    private userFilterInterfaceService: UserFilterInterfaceService,
    private urlParameterService: UrlParameterService) {

  }



  public updateSortDirection(userFilterChanged: UserFilterChanged): Map<string, SortType> {
    let sortDirection: Map<string, SortType> = new Map<string, SortType>();

    if (!userFilterChanged) {
      return sortDirection;
    }

    const filterParameter: UserFilterParameter = _(userFilterChanged.filterParameters)
      .filter(p => p.filterParameterValue === '["DESC"]' || p.filterParameterValue === '["ASC"]')
      .first();


    if (!filterParameter) {
      return sortDirection;
    }

    const sortField: string = filterParameter.filterParameterName;
    let sortType: SortType;
    if (filterParameter.filterParameterValue === '["ASC"]') {
      sortType = SortType.ASC;

    } else if (filterParameter.filterParameterValue === '["DESC"]')
      sortType = SortType.DESC;

    else {
      sortType = SortType.NONE;

    }

    sortDirection.set(sortField, sortType);
    return sortDirection;
  }

  public updateSortParameter(userFilterChanged: UserFilterChanged): UrlParameter {

    if (!userFilterChanged) {
      return null;
    }

    const userFilterSortParameter: UserFilterParameter = !!userFilterChanged.filterParameters ? _(userFilterChanged.filterParameters)
      .find(fp => !fp.filterParameterName.endsWith('Facet')) : null;

    if (!userFilterSortParameter) {
      return null;
    }

    const key: string = userFilterSortParameter.filterParameterName;
    let value: string = userFilterSortParameter.filterParameterValue;
    value = JSON.parse(value);
    return new UrlParameter(key, value);
  }

  public updateFacetSearchParameters(userFilterChanged: UserFilterChanged): UrlParameter[] {

    if (!userFilterChanged) {
      return null;
    }

    const userFilterParameters: UserFilterParameter[] = !!userFilterChanged.filterParameters ? _(userFilterChanged.filterParameters)
      .filter(fp => fp.filterParameterName.endsWith('Facet')).value() : [];

    return this.updateParameters(userFilterParameters);
  }

  public changeFilter(facetSearchParameters: UrlParameter[], sortParameters: UrlParameter[], filterType: string, userFilterChanged: UserFilterChanged): void {
    const filterId: string = userFilterChanged?.filterId;
    const filterName: string = userFilterChanged?.filterName;
    const merged: UrlParameter[] = (facetSearchParameters || []).concat((sortParameters || []));
    const filterParameters: UserFilterParameter[] = this.toUserFilterParameters(merged);
    const userFilterChangedCopy: UserFilterChanged = new UserFilterChanged(filterId, filterType, filterName, filterParameters);
    this.userFilterInterfaceService.changeFilter(userFilterChangedCopy);
  }

  private toUserFilterParameters(parameters: UrlParameter[]): UserFilterParameter[] {
    return (parameters || []).map(p => this.toUserFilterParameter(p));
  }

  private toUserFilterParameter(parameter: UrlParameter): UserFilterParameter {
    const filterParameterName: string = parameter.field;
    const filterParameterValue: string = JSON.stringify(parameter.value);
    return new UserFilterParameter(filterParameterName, filterParameterValue);
  }

  private needsUpdate(parameters: UrlParameter[], userFilterParameter: UserFilterParameter): boolean {
    const key: string = userFilterParameter.filterParameterName;
    const value1: string = JSON.parse(userFilterParameter.filterParameterValue);
    const value2: string = _(parameters).find(p => p.field === key)?.value;
    return !_.isEqual(value1, value2);
  }

  private updateParameters2(parameters: UrlParameter[], userFilterParameters: UserFilterParameter[]): UrlParameter[] {
    const updatedParameters: UrlParameter[] = _(userFilterParameters).map(p => this.urlParameterService.convertUserFilterParamToUrlParam(p)).value();
    return _(parameters)
      .keyBy('field')
      .merge(_.keyBy(updatedParameters, 'field'))
      .values()
      .value();
  }
  private updateParameters(userFilterParameters: UserFilterParameter[]): UrlParameter[] {
    return _(userFilterParameters).map(p => this.urlParameterService.convertUserFilterParamToUrlParam(p)).value();
  }


}

<div class="modal-header">
  <h4 class="modal-title">{{ 'user.filter.save.dialog.title' | translate }}</h4>
</div>
<div class="modal-body">
  <p><input id="user-filter-save-input"
      [(ngModel)]="filterName" /></p>
</div>
<div class="modal-footer">
  <button type="button"
  class="btn btn-secondary"
  (click)="clickCancel()">{{ 'button.cancel' | translate }}</button>
  <button type="button"
    class="btn btn-primary"
    (click)="clickOk()">{{ 'button.ok' | translate }}</button>
</div>
import { Component, Input, OnInit } from "@angular/core";
import { Category } from "../../../../model/category";
import { ProductHierarchyObject } from "../../../../model/product-hierarchy-object/product-hierarchy-object";
import { Page } from "../../../../model/pim-response/page";
import { Document } from "../../../../model/document/document";
import { LocalizedString } from "../../../../model/localized-string";
import { Router } from "@angular/router";
import { CategoryUsageService } from "../../../../service/category-usage.service";
import { LocalizedStringService } from "../../../../service/localized-string.service";
import { ProductHierarchyObjectUsageService } from "../../../../service/product-hierarchy-object-usage.service";
import { Pageable } from "../../../../model/pageable";


@Component({
  selector: 'pho-usage',
  templateUrl: './pho-usage.component.html',
  styleUrls: ['./pho-usage.component.less']
})
export class PhoUsageComponent implements OnInit {

  @Input() public pho: ProductHierarchyObject;

  public documents: Page<Document> = new Page();

  public getLocalizedStringsValue: (value: LocalizedString[]) => string = (value: LocalizedString[]) => this.localizedStringService.getLocalizedStringsValue(value);

  constructor(
    private router: Router,
    private phoUsageService: ProductHierarchyObjectUsageService,
    private localizedStringService: LocalizedStringService
  ) { }

  ngOnInit() {
    this.switchDocumentPage(new Pageable());
  }

  public switchDocumentPage(pageable: Pageable) {
    this.phoUsageService.findAssignedDocuments(this.pho, pageable).subscribe((documents: Page<Document>) => this.documents = documents);
  }

  public navigateToDocument(document: Document) {
    this.router.navigateByUrl('document/' + document.id);
  }
}
